<script setup>

const props = defineProps({
  data: Object
})

const box = computed(() => props.data || {})
const linkName = computed(() => box.value.linkDescription || "Zobacz więcej")

</script>

<template>
<div class="flex flex-col">
  <div class="aspect-[3/2] bg-gray-100 bg-center bg-cover"
       :style="{'background-image': `url(${box?.url})`}">
  </div>
  <div v-if="box.header" class="p-6 pb-0 text-xl">
    {{ box?.header }}
  </div>
  <div class="p-6 font-extralight grow text-lg">
    {{ box?.content }}
  </div>
  <div v-if="box?.link" class="flex justify-center">
    <a :href="box?.link" class="text-azure">{{ linkName }}</a>
  </div>
</div>
</template>
